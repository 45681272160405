export default {
  props: {
    value: { type: Boolean, required: true },
  },

  computed: {
    isOpened: {
      get () {
        return this.value
      },
      set (isOpened) {
        this.$emit("input", isOpened)
      },
    },
  },
}
