// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WLfxQLHS{display:flex;flex-direction:column;gap:24px;position:relative}.WLfxQLHS p{color:#fff;font-size:max(.88rem,min(.94vw,1.13rem));margin:0;width:50%}@media(max-width:991.98px){.WLfxQLHS p{width:100%}}.WLfxQLHS a{color:#52b9c6;font-size:max(1.13rem,min(1.15vw,1.38rem));font-weight:600}.iEUldNq7{color:#b1ec52;font-size:max(2rem,min(2.5vw,3rem))}.UGzgMGGG{display:flex;flex-direction:column;gap:16px}.Qs\\+5Wfwe{bottom:-60px;position:absolute;right:0}@media(max-width:991.98px){.Qs\\+5Wfwe{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "WLfxQLHS",
	"title": "iEUldNq7",
	"block": "UGzgMGGG",
	"bg": "Qs+5Wfwe"
};
module.exports = ___CSS_LOADER_EXPORT___;
