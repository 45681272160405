// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Y4WfPqQB{background:linear-gradient(180deg,rgba(0,0,0,.78),rgba(0,0,0,.68) 98.97%);bottom:0;cursor:pointer;left:0;opacity:0;overflow-x:hidden;overflow-y:auto;padding:3rem;top:0;transition:visibility 0s,opacity var(--transition-duration) linear;transition-delay:var(--transition-duration),0s;visibility:hidden;z-index:100}.idvkW0Rc{opacity:1;transition-delay:0s,var(--transition-duration);visibility:visible}@media(max-width:767.98px){.Y4WfPqQB{padding:1rem}}.Okoyal5E{background:#fff;border-radius:1.5rem;cursor:auto;height:-webkit-max-content;height:-moz-max-content;height:max-content;max-width:100%;overflow:hidden;padding:2.69rem 5rem 2.81rem 2.5rem;width:-webkit-max-content;width:-moz-max-content;width:max-content;word-break:break-word}@media(max-width:767.98px){.Okoyal5E{padding:2.06rem 2.5rem 1.56rem 1.25rem}}.Bl6-kf1t{background:#1a153f}.pAxQwwGd{background:none;color:#1a153f;cursor:pointer;height:1rem;right:1.5rem;top:1.5rem;width:1rem}@media(hover:hover){.pAxQwwGd:hover{opacity:.8}}@media(hover:none){.pAxQwwGd:active{opacity:.8}}.pAxQwwGd:active{opacity:.7}.pAxQwwGd svg{height:inherit;width:inherit}.Hr9xDItA{font-size:2rem;line-height:1.41;margin-bottom:.84em}@media(max-width:767.98px){.Hr9xDItA{font-size:1.25rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "Y4WfPqQB",
	"modalOpened": "idvkW0Rc",
	"content": "Okoyal5E",
	"contentDark": "Bl6-kf1t",
	"close": "pAxQwwGd",
	"heading": "Hr9xDItA"
};
module.exports = ___CSS_LOADER_EXPORT___;
