
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import modalMixin from "@/mixins/modal"

export default {
  mixins: [modalMixin],

  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    transitionDuration: 100,
  }),

  watch: {
    async isOpened (isOpened) {
      const { modal } = this.$refs
      if (!modal) return

      if (isOpened) {
        disableBodyScroll(modal, { reserveScrollBarGap: true })
        await this.$nextTick()
        modal?.focus?.()
      } else setTimeout(() => enableBodyScroll(modal), this.transitionDuration)
    },
  },
}
